<template>
  <div class="list-card">
    <search-bar :entity-type="entityType" @query="handleQuery" />
    <tool-bar
      v-if="!isSelectionMode"
      :actions="listActions"
      :is-filter-active="isFilterActive"
      :sorting="sorting"
      :current-sort-by="currentSortBy"
      :title="`${title} (${itemsCount || filteredItems.length})`"
      @click:action="$emit('click:action', $event)"
      @click:filter="$emit('click:filter', $event)"
      @click:sort="$emit('click:sort', $event)"
      @click:filter-icon="$emit('click:filter-icon', $event)"
    >
      <template #extra-items>
        <slot name="toolbar:extra-items" />
      </template>
    </tool-bar>
    <tool-bar-selection
      v-else
      :actions="groupActions"
      :sorting="sorting"
      :current-sort-by="currentSortBy"
      :selected-count="selectedCount"
      @click:back="clearSelection"
      @click:sort="$emit('click:sort', $event)"
    >
      <template #extra-items>
        <slot name="toolbar-selection:extra-items" />
      </template>
    </tool-bar-selection>

    <v-divider />
    <v-virtual-scroll
      v-if="!$vuetify.breakpoint.mobile || !isMapState"
      :bench="1"
      :items="filteredItems"
      :item-height="itemHeight"
      @scroll="handleScroll"
    >
      <template #default="{ item, index }">
        <v-hover
          #default="{hover}"
          @input="
            !isSelectionMode && $emit('active', { id: item.id, active: $event })
          "
        >
          <slot
            name="item"
            :item="item"
            :hover="hover"
            :selected="selectedIds[item.id]"
            :is-selection-mode="isSelectionMode"
            :select="() => select(item.id)"
            :index="index"
          />
        </v-hover>
      </template>
    </v-virtual-scroll>
    <view-toggle-button
      v-if="$vuetify.breakpoint.mobile && !isMapState"
      bottom
      right
      absolute
      class="view-toggle-btn"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';

import SearchBar from './SearchBar';
import ToolBar from './ToolBar';
import ToolBarSelection from './ToolBarSelection';
import ViewToggleButton from '@/layouts/map/footerMenu/ViewToggleButton.vue';
import { useToggleView } from '@/layouts/map/footerMenu/useToggleView';
import { filterEUI } from '@/utils/filterEUI';
export default {
  components: {
    ViewToggleButton,
    SearchBar,
    ToolBar,
    ToolBarSelection
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    itemsCount: {
      type: Number,
      default: undefined
    },
    listActions: {
      type: Array,
      default: () => []
    },
    sorting: {
      type: Array,
      default: () => []
    },
    currentSortBy: {
      type: String,
      default: 'default'
    },
    groupActions: {
      type: Array,
      default: () => []
    },
    itemActions: {
      type: [Array, Function],
      default: () => []
    },
    returnObject: {
      type: Boolean,
      default: true
    },
    to: {
      type: [Function, null],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    infinite: {
      type: Boolean,
      default: false
    },
    itemHeight: {
      type: Number,
      default: 72
    },
    entityType: {
      type: String,
      default: 'object'
    },
    filterFunction: {
      type: Function,
      default: undefined
    },
    isFilterActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, root }) {
    const selectedIds = ref({});
    const query = ref('');
    const needScroll = ref(true);

    const { isMapState } = useToggleView(root.$vuetify);
    const select = itemId => {
      if (!selectedIds.value[itemId]) {
        selectedIds.value = { ...selectedIds.value, [itemId]: true };
      } else {
        const { [itemId]: removed, ...rest } = selectedIds.value;
        selectedIds.value = rest;
      }
    };

    const clearSelection = () => {
      selectedIds.value = {};
    };

    watch(
      () => selectedIds.value,
      () => emit('selectedItemIds', Object.keys(selectedIds.value))
    );

    const selectedCount = computed(() => Object.keys(selectedIds.value).length);
    const isSelectionMode = computed(() => selectedCount.value > 0);
    const hasItemActions = computed(() => true);
    const handleQuery = value => {
      query.value = value;
      emit('change:query', value);
    };

    const filterFunction = (item, inputQuery) => {
      if (!props.filterFunction) {
        return (
          item.name?.toLowerCase().includes(inputQuery?.toLowerCase()) ||
          item.id?.toLowerCase().includes(inputQuery?.toLowerCase()) ||
          filterEUI(item, inputQuery)
        );
      }

      return props.filterFunction(item, inputQuery);
    };

    const filteredItems = computed(() => {
      return props.items.filter(item => filterFunction(item, query.value));
    });

    const selectAll = () => {
      selectedIds.value = filteredItems.value.reduce((acc, cur) => {
        acc[cur.id] = true;
        return acc;
      }, {});
    };

    const getItemRoute = item => {
      if (!props.to) {
        return null;
      }
      if (typeof props.to === 'function') {
        return props.to(item);
      }
      return props.to;
    };
    const getItemActions = item => {
      if (!props.itemActions) {
        return null;
      }
      if (typeof props.itemActions === 'function') {
        return props.itemActions(item);
      }
      return props.itemActions;
    };

    const handleScroll = e => {
      if (!props.infinite) return;
      const { clientHeight, scrollTop, scrollHeight } = e.target;

      if (needScroll.value && clientHeight + scrollTop > scrollHeight - 40) {
        emit('infinite');
        needScroll.value = false;
      } else {
        needScroll.value = true;
      }
    };

    return {
      selectedIds,
      select,
      isSelectionMode,
      selectedCount,
      clearSelection,
      getItemRoute,
      hasItemActions,
      getItemActions,
      handleScroll,
      isMapState,
      handleQuery,
      filteredItems,
      selectAll
    };
  }
};
</script>

<style lang="sass" scoped>
.list-card
  display: flex
  height: 100%
  width: inherit
  flex-direction: column
  ::v-deep .actions-wrapper
    > *
      margin-right: 0 !important
.view-toggle-btn
  margin-bottom: 38px
</style>
