import { usePopup } from '@/compositions/popup';
import { genMenuItemDefault, genMenuItemAdd } from '@/utils/list-generators';
import { useMap } from '@/compositions/map';

//TODO: implement real group actions

export function useObjectListActions({selectAll}) {
  const popup = usePopup();
  const {
    getCenter,
    markers: { getDefaultCenter }
  } = useMap();

  return {
    listActions: [
      genMenuItemAdd(
        () => {
          popup.open({
            component: () => import('@/modules/objects/ui/ObjectEdit.vue'),
            props: {
              defaultLonLat: getDefaultCenter()
            }
          });
        },
        {
          title: 'Create new'
        }
      ),
      genMenuItemAdd(
        () =>
          popup.open({
            component: () =>
              import('@/modules/objects/ui/objects-from-devices/index.vue'),
            props: {
              centerMap: getCenter()
            }
          }),
        {
          id: 'add from template',
          icon: '$add',
          title: 'Create from devices',
          disabled: false
        }
      ),
      genMenuItemDefault({
        id: 'select_all',
        icon: '$checkbox_checked',
        title: 'Select all',
      }, {
          click: selectAll
      }),
      genMenuItemDefault({
        id: 'import',
        icon: '$import',
        title: 'Import',
        disabled: true
      })
    ]
  };
}
